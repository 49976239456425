// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("@nathanvda/cocoon")

import "startbootstrap-sb-admin-2/vendor/jquery/jquery.min"
import "startbootstrap-sb-admin-2/vendor/jquery-easing/jquery.easing.min"
import "startbootstrap-sb-admin-2/vendor/bootstrap/js/bootstrap.bundle.min"

import "startbootstrap-sb-admin-2/css/sb-admin-2.min.css"
import "vue-multiselect/dist/vue-multiselect.min.css"
import "../stylesheets/application.scss"

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
